<template>
  <div
    v-if="width > 700"
    class="bg-[#f4f4f4] flex rounded-lg px-2 py-1 border-2 border-b-gray-300"
  >

    <input
      id="searchBar"
      @click="selectInput"
      @input="searchProducts"
      type="text"
      class="mx-2 focus:outline-none text-md bg-[#f4f4f4]"
      :class="isPortrait ? 'w-[11rem]' : 'w-[15rem]'"
      :placeholder="$t('Pos.SearchProducts')"
    />

    
    <div class="flex flex-row gap-2 max-w-fit my-auto">
      
      <iconSearch :size="20" :color="'#000'" />
    </div>
  </div>
  <LbrxKeyboardPopup
    :active="modalKeyboard.active"
    @closePopupKeyboard="closePopupKeyboard"
  />
</template>

<script>
import LbrxKeyboardPopup from "@/components/LbrxKeyboardPopup.vue";
import iconSearch from "./icons/iconSearch.vue";
export default {
  name: "LbrxSearchBar",
  components: {
    iconSearch,
    LbrxKeyboardPopup,
  },
  data() {
    return {
      textInput: "",
      modalKeyboard: {
        active: false,
      },
      width: window.screen.width,
      showClose: false,
    };
  },
  props: {
    posConfig: {
      required: true,
      value: Object,
    },
    isPortrait: {
      required: true,
      value: Boolean,
    },
  },
  methods: {
    searchProducts(event) {
      const inputValue = event.target.value;
      window.dispatchEvent(
        new CustomEvent("cart-search", {
          detail: {
            search: inputValue,
          },
        })
      );
      
    },
    selectInput() {
      if (this.posConfig.posConfig.showKeyboard == 1) {
        this.modalKeyboard.active = true;
      }
    },
    closePopupKeyboard(text) {
      const inputValue = document.getElementById("searchBar");
      inputValue.value = text;
      window.dispatchEvent(
        new CustomEvent("cart-search", {
          detail: {
            search: text,
          },
        })
      );
      this.modalKeyboard.active = false;
      this.showClose = true;
    },
    resetFilter(){
      const inputValue = document.getElementById("searchBar");
      inputValue.value = "";
      this.showClose=false;
      window.dispatchEvent(new CustomEvent("reset-cart-search"));
    }
  },
};
</script>

<style scoped></style>