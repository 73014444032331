<template>
  <a-modal centered v-model:open="isOpen" :footer="null" class="receipt-modal">
    <template #title>
      <div>
        <a-button class="print-btn" @click="printReport">
          {{ $t("Pos.Print") }}
          <template #icon>
            <PrinterOutlined />
          </template>
        </a-button>
      </div>
    </template>
    <div class="receipt-container">
      <!-- Receipt Header -->

      <div class="receipt-header">
        <img :src="posConfig?.store.logo" class="logo" />
        <h2 class="text-center font-bold text-xl mb-2">
          {{ posConfig?.store.name }}
        </h2>

        <div class="flex flex-col">
          <span class="text-center text-sm">
            {{ posConfig?.store.address }}
          </span>
          <span class="text-center text-sm">
            {{ posConfig?.store.phone }}
          </span>
        </div>
      </div>

      <!-- content -->
      <div class="receipt-content">
        <!-- sales article -->
        <h3 class="text-[18px] text-center font-bold mt-2 mb-2">
          RAPPORT DE VENTE PAR ARTICLE
        </h3>
        <div v-if="data?.categories?.length">
          <table class="w-full">
            <thead>
              <tr class="border-b border-dashed border-gray-200">
                <th class="text-left py-2 font-bold">Qté</th>
                <th class="text-left py-2 font-bold">ARTICLE</th>
                <th class="text-right py-2 font-bold">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data.products" :key="item.id">
                <td class="text-left">
                  {{ item.quantity || "0" }}
                </td>
                <td class="text-left">
                  {{ item.name }}
                </td>
                <td class="text-right">
                  {{ item.total }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center py-4">Aucun article disponible.</div>

        <div class="mt-4 border-t border-dashed border-gray-200">
          <div class="flex justify-between pt-2">
            <span class="font-bold">TOTAL HT </span>
            <span>{{ data.sub_total_revenue }}</span>
          </div>
          <div class="flex justify-between">
            <span class="font-bold">TOTAL TVA</span>
            <span>{{ data.total_vat }}</span>
          </div>
          <div class="flex justify-between">
            <span class="font-bold">TOTAL TTC</span>
            <span>{{ data.total_revenue }}</span>
          </div>
        </div>
        <!-- sales grp -->
        <h3 class="text-[18px] text-center font-bold mt-2 mb-2">
          RAPPORT DE VENTE PAR GROUPE
        </h3>
        <div v-if="data?.categories?.length">
          <table class="w-full">
            <thead>
              <tr class="border-b border-dashed border-gray-200">
                <th class="text-left py-2 font-bold">Qté</th>
                <th class="text-left py-2 font-bold">Groupe</th>
                <th class="text-right py-2 font-bold">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data.categories" :key="item.id">
                <td class="text-left">
                  {{ item.quantity || "0" }}
                </td>
                <td class="text-left">
                  {{ item.name }}
                </td>
                <td class="text-right">
                  {{ item.total }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center py-4">Aucun article disponible.</div>
        <!-- stock -->
        <h3 class="text-[18px] text-center font-bold mt-2 mb-2">
          RAPPORT DE STOCK
        </h3>
        <div v-if="data?.stock?.length">
          <table class="w-full">
            <thead>
              <tr class="border-b border-dashed border-gray-200">
                <th class="text-left py-2 font-bold">Article</th>
                <th class="text-right py-2 font-bold">Qté</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data.stock" :key="item.id">
                <td class="text-left">
                  {{ item.stockable?.name || "Unknown" }}
                </td>
                <td class="text-right">{{ item.quantity }} {{ item.unit }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center py-4">
          Aucun article en stock disponible.
        </div>
        <!-- report -->
        <h3 class="text-[18px] text-center font-bold mt-2 mb-2">
          RAPPORT DE CAISSE
        </h3>
        <div v-if="data?.drawerInfo">
          <div class="flex justify-between">
            <span class="font-bold">Montant d'ouverture </span>
            <span>{{ data.drawerInfo.opening_amount }}</span>
          </div>
          <div class="flex justify-between">
            <span class="font-bold">Vente en espèces </span>
            <span>{{ data.drawerInfo.total_cash_payment }}</span>
          </div>
          <div class="flex justify-between">
            <span class="font-bold">Vente autre </span>
            <span>{{ data.drawerInfo.total_other_methods }}</span>
          </div>
          <div class="flex justify-between">
            <span class="font-bold">Montant prévue</span>
            <span>{{ data.drawerInfo.total_cash }}</span>
          </div>
        </div>
      </div>

      <!--footer-->
      <div
        class="receipt-footer mt-2 pt-2 border-t border-dashed border-gray-200"
      >
        <p class="text-center text-xs">
          {{ formattedDate() }}
        </p>
        <h3 class="font-bold text-center text-sm">
          POSYS - Point Of Sale System
        </h3>
        <p class="text-center text-sm">www.posys.io</p>
      </div>
    </div>
  </a-modal>
</template>


<script lang="ts">
import {
  defineComponent,
  defineProps,
  defineEmits,
  onMounted,
  watch,
} from "vue";

export default defineComponent({
  name: "LbrxPreviewModal",
});
</script>

<script setup lang="ts">
import dayjs from "dayjs";

import { PrinterOutlined } from "@ant-design/icons-vue";

import { ref } from "vue";
const props = defineProps<{
  open: boolean;
  data: any;
  posConfig: any;
}>();

const emit = defineEmits<{
  (e: "update:open", value: boolean): void;
  (e: "print-report", data: any): void;
}>();

const isOpen = ref(props.open);
watch(
  () => props.open,
  (newValue) => {
    isOpen.value = newValue;
  }
);
watch(isOpen, (newValue) => {
  emit("update:open", newValue);
});

const formattedDate=()=>{
  return dayjs().format("YYYY/MM/DD HH:mm");
}

const printReport = () => {
  emit("print-report", props.data);
};
</script>

<style scoped>
.print-btn {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.receipt-header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.logo {
  max-width: 30%;
}

.receipt-container {
  font-family: "Courier New", monospace;
  max-height: 60vh;
  overflow-y: auto;
  padding: 0 8px;
}

.receipt-content {
  font-size: 14px;
}

.receipt-footer {
  font-size: 12px;
}

/* custom scrollbar */
.receipt-container::-webkit-scrollbar {
  width: 6px;
}

.receipt-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.receipt-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.receipt-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>