import {authHeader, ApiConfigs} from "../_helpers";

export const stockItemsService = {
    getAll,
    getAllTransactions,
    getById,
    create,
    delete: _delete,
    update,
    getStockItems,
    filterStockItemsName
};

function getStockItems(keys:string,value:string,currentPage: number, perPage: number) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.stock_items.getAll}?keys=${keys}&values=${value}&page=${currentPage}&per_page=${perPage}`, requestOptions).then(handleResponse);
}

function filterStockItemsName(key:string,value:string, name:string, page:any, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stock_items.getAll}?keys=${key}&values=${value}&stockable_name=${name}&page=${page}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function getAll() {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stock_items.getAll}`, requestOptions)
        .then(
            handleResponse
        );
}

function getAllTransactions(param: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stock_items.getAllTransaction+param}`, requestOptions)
        .then(
            handleResponse
        );
}

function getById(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stock_items.getById.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function create(payload:object){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stock_items.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function _delete(id: string){
    const requestOptions={
        method:"DELETE",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.stock_items.delete.replace(':id', id)}`, requestOptions)
        .then(handleResponse);
}

function update(id: string, payload: object){
    console.log('ertert', id)
    const requestOptions={
        method:"PUT",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.stock_items.update.replace(':id', id)}`, requestOptions)
    .then(handleResponse);
}


function handleResponse(response: any) {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem("user");
            location.reload();
        }
        const error = (response && response.message) || response.status || response.statusText;
        return Promise.reject(error);
    }
    return response.json().then((rep: any) => {
        return rep;
    });
}