<template>
  <span class="flex text-xl font-bold my-4">{{ $t("Pos.BookedOrders") }}</span>
  <div class="bg-white h-auto flex flex-col rounded-md px-4 py-3">
    <div class="flex px-2 py-3 border-b-2 border-b-gray-200">
      <div class="max-w-fit my-auto">
        <iconSearchV2 :size="20" :color="'#000'" />
      </div>
      <input
        id="searchBarOrder"
        @click="selectInput"
        @input="searchSales"
        type="text"
        class="w-[25rem] mx-2 focus:outline-none text-md"
        :placeholder="$t('Pos.SearchOrders')"
      />
    </div>
    <div class="mt-6 h-full">
      <a-table
        class="ant-table-striped"
        size="middle"
        :columns="columns"
        :data-source="sales"
        :pagination="false"
        @focus="test"
        :row-class-name="
          (_record, index) =>
            index == selectedIndex
              ? 'table-selected'
              : index % 2 === 1
              ? 'table-striped'
              : null
        "
      >
        <template #bodyCell="{ column, text, record, index }">
          <div @click="selectSale(record, index)">
            <template v-if="column.dataIndex === 'id'">
              <span v-if="posConfig.posConfig.table_item_display">
                <a
                  v-if="
                    posConfig.posConfig.table_item_display == 'order_number'
                  "
                >
                  <span
                    v-if="
                      record.order_number !== null &&
                      record.order_number != '' &&
                      record.order_number != 'null'
                    "
                    ># {{ record.order_number }}</span
                  >
                  <span v-else># {{ text }}</span>
                </a>
                <a v-if="posConfig.posConfig.table_item_display == 'id'">
                  {{ "#" + text }}
                </a>
              </span>

              <a v-else posConfig.posConfig.table_item_display>{{
                "#" + text
              }}</a>
            </template>
            <template v-if="column.dataIndex === 'created_at'">
              <a>{{ formatDate(text) }}</a>
            </template>
            <template v-if="column.dataIndex === 'total'">
              <div class="flex items-center gap-2" v-if="record.is_offer == 1">
                <a>{{ $t("Pos.Offer") }}</a>
                <iconBadge :size="20" :color="'#FFC94A'" />
              </div>
              <a v-else>{{
                formatAmount(text, currencyCode, decimalPrecision)
              }}</a>
            </template>
            <template
              v-if="column.dataIndex === 'status' && selectedFilter.id == 3"
            >
              <a>{{ "Processing" }}</a>
            </template>
          </div>
        </template>
      </a-table>
      <div class="flex justify-end mt-4">
        <a-pagination
          v-model:current="currentPage"
          :total="totalItems"
          @change="handlePageChange"
          :default-page-size="perPage"
          :showSizeChanger="false"
        />
      </div>
    </div>
    <LbrxKeyboardPopup
      :active="modalKeyboard.active"
      @closePopupKeyboard="closePopupKeyboard"
    />
  </div>
</template>

<script>
import LbrxKeyboardPopup from "./LbrxKeyboardPopup.vue";
import iconSearchV2 from "./icons/iconSearchV2.vue";
import iconBadge from "./icons/iconBadge.vue";
import moment from "moment/moment";
import { currency } from "@/_helpers";
export default {
  name: "LbrxListBookedOrders",
  components: {
    iconSearchV2,
    iconBadge,
    LbrxKeyboardPopup,
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("Pos.OrderId"),
          dataIndex: "id",
        },
        {
          title: this.$t("Pos.Date"),
          dataIndex: "created_at",
        },
        {
          title: this.$t("Pos.TotalSales"),
          dataIndex: "total",
        },
      ],
      currentPage: 1,
      selectedIndex: -1,
      modalKeyboard: {
        active: false,
      },
      primaryLight: JSON.parse(localStorage.getItem("themeTemplate"))
        .primaryLight,
    };
  },
  props: {
    sales: {
      required: true,
      value: Array,
    },
    totalItems: {
      required: true,
      value: Number,
    },
    perPage: {
      required: true,
      value: Number,
    },
    posConfig: {
      required: true,
      value: Object,
    },
    currencyCode: {
      required: false,
      value: String,
    },
    currencyDirection: {
      required: false,
      value: String,
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3,
    },
    selectedFilter: {
      required: true,
      value: Object,
      default: {},
    },
  },
  methods: {
    formatAmount(amount, currencyCode, decimalPrecision) {
      return currency.formatAmount(amount, currencyCode, decimalPrecision);
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale == "ar" ? "ar-tn" : this.$i18n.locale)
        .format("LLLL");
    },
    selectSale(item, index) {
      this.selectedIndex = index;
      this.$emit("selectSale", item);
    },
    searchSales(event) {
      const inputValue = event.target.value;
      this.$emit("searchSales", inputValue);
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.$emit("handlePageChange", page);
    },
    selectInput() {
      if (this.posConfig.posConfig.showKeyboard == 1) {
        this.modalKeyboard.active = true;
      }
    },
    closePopupKeyboard(text) {
      const inputValue = document.getElementById("searchBarOrder");
      inputValue.value = text;
      this.$emit("searchSales", inputValue.value);
      this.modalKeyboard.active = false;
    },
  },
};
</script>

<style>
[data-doc-theme="light"] .ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

[data-doc-theme="dark"] .ant-table-striped :deep(.table-striped) td {
  background-color: rgb(29, 29, 29);
}

.table-striped {
  background-color: #f5f5f5;
}

.table-selected {
  background-color: v-bind(primaryLight);
}
</style>